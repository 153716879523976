.parent {
    display: flex;
  }
  
  .child {
    flex: 1;
    margin: 5px;
    padding: 10px;
  }

  .tothucan{
    margin-top: 100px;
    display: flex;
    justify-content: center;
   }

  @media only screen and  (min-width: 955px) {
    .tothucan{
        margin-top: 100px;
       
       }

}