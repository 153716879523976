.banner {
    white-space: nowrap;
    position: absolute; 
    overflow: hidden; /* nếu không có dòng này bề rộng của web bị mở rộng không tốt */
    position: relative;
    height: 100px;
    width: 100%;
  }
  
  .banner-text {
   
    color: rgb(255, 0, 0);
    display: inline-block;
    position: absolute;
    animation: marquee 20s linear infinite;
  }
  

  @media only screen and (max-width: 1920px) {



    @keyframes marquee {
      from {
        transform: translateX(220%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  
  
  }



  @media only screen and (max-width: 1124px) {



    @keyframes marquee {
      from {
        transform: translateX(180%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  
  
  }

  @media only screen and (max-width: 800px) {



    @keyframes marquee {
      from {
        transform: translateX(120%);
      }
      to {
        transform: translateX(-100%);
      }
    }
  
  
  }



  @media only screen and (max-width: 500px) {



  @keyframes marquee {
    from {
      transform: translateX(80%);
    }
    to {
      transform: translateX(-100%);
    }
  }


}