@import 'antd/dist/reset.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'thenautigal';
  src: url('/fonts/thenautigal-regular-onhkc.ttf') format('truetype');
}

.font-thena {
  font-family: thenautigal;
}
.font-quick-sand {
  font-family: Quicksand, sans-serif;
}

:root {
  --foreground-rgb: 000, 000, 000;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 255, 255, 255;
    --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb)))
    rgb(var(--background-start-rgb));
  position: relative;
  font-family: Quicksand, sans-serif;
}

.text-full-colors {
  background-image: linear-gradient(rgb(249, 212, 35), rgb(255, 78, 80));

  color: rgb(28, 127, 71);
  letter-spacing: 2px;
  -webkit-background-clip: text;
  -webkit-text-stroke-color: rgb(255, 253, 6);

  -webkit-text-fill-color: transparent;

  --color-background: #000119;
  --stroke-width: calc(1em / 16);
  --letter-spacing: calc(1em / 8);

  @media screen and (max-width: 767px) {
    --font-size: 16px;
  }

  background-clip: text;
  background-image: linear-gradient(to right, #0004ff, #ff00b3, #ff0000);
  color: var(--color-background);
  letter-spacing: var(--letter-spacing);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: var(--stroke-width);
}

.text-shadow {
  text-shadow: 4px 6px 4px rgba(0, 0, 0, 0.25);

  @media screen and (min-width: 767px) {
    text-shadow: 4px 6px 6px rgba(0, 0, 0, 0.9);
  }
}

.btn-primary {
  background-image: linear-gradient(rgb(157, 251, 200), rgb(245, 241, 134));
}

.ant-form-item-label {
  padding: 0 !important;
}

.text-beautiful {
  --color-background: #000119;
  --stroke-width: calc(1em / 16);

  background-clip: text;
  background-image: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
  color: var(--color-background);
  padding: calc(--stroke-width / 2);
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: var(--stroke-width);
}

#checkdv_1,
#USE_CHAT_GPT_AI_ROOT,
#USE_CHAT_GPT_AI_ROOT_Context_Menu {
  display: none;
}

.image-gallery-image {
  @apply bg-gray-100;
}
