.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #b5adad;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



// cs các ngôi sao begin
.star-container {
  position: relative;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.black-star {
  position: absolute;
  color: black;
}

.yellow-star {
  position: absolute;
  color: yellow;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 30px;
  height: 30px;
//  background-color: blue;
  margin-right: 10px; /* Khoảng cách giữa các thẻ div */
}
// cs các ngôi sao end



//gms


