.inforadress{
 font-size: 20px;

}

@media only screen and  (max-width: 640px) {
    .inforadress{
        font-size: 15px;
       
       }

}

@media only screen and  (max-width: 441px) {
    .inforadress{
        font-size: 10px;
       
       }

}
.red-text{
    color: red;
  
  }

  .bookingroom{
    top : -30px;
   
    }
   
    @media only screen and  (max-width: 768px) {
       .bookingroom{
           top : 40px;
          
           }
   
   }